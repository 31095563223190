<character>
  <!--  
todo  
1- mobile character full screen
2- loader
-->
  <!-- layout -->

  <div id="buttons">
    <!-- <lottie-player id="loader" src={createBlob(state.handload)}  background="transparent" speed="1"
           direction="1" mode="normal" loop autoplay>
       </lottie-player> -->

    <div class="man-container">
      <div class="tamkin-three-container"></div>
      <!-- <img src={loadFile('/assets/tamkin_website/img/MAN10.png')} class="img-man"> -->
    </div>
  </div>

  <script>
    // jQuery is now available in all project see webpack.config.js:126
    import $ from 'jquery';
    import * as THREE from 'three';
import * as zip from '@zip.js/zip.js';

import * as riot from "riot";

    let play;
    let action;
    let Init_action;
    let speedCaracter = 1;
    let controls;

    window.speed = 1;
    window.state_animation = "idle";
    window.tmk_trans_background_100 = ("Background 100%");
    window.tmk_trans_background_50 = ("Background 50%");
    window.tmk_trans_transparent = ("Transparent");

    //import fares_rigging from "./player_files/files3D/fares_rigg_optmized_001.fbx";
    // import fares_rigging from "./player_files/files3D/anim_clothes_off 01.fbx";
    // import fares_rigging from "./player_files/files3D/fares_01.fbx";
    import fares_rigging from "./player_files/files3D/fares_001.fbx";
    import saaied_rigging from "./player_files/files3D/saaied_004.fbx";
    
    

    import qassem_rigging from "./player_files/files3D/qassem_002.fbx";
    // import qassem_rigging from "./player_files/files3D/qasem_01.fbx";

    // import sarra_rigging from "./player_files/files3D/sara_0.fbx";
    import sarra_rigging from "./player_files/files3D/sara_003.fbx";


    // import qassem_rigging from "./player_files/files3D/Qassem_anim01.fbx";
    // import qassem_rigging from "./player_files/files3D/qassem_Anim_scale.fbx";

    import pose_animation_Fares from "./player_files/files3D/new_b4_after.glb";
   
var delta;
    
  //  import pose_animation from "./player_files/files3D/b4_after_animation_fbx.fbx";

    
    // import pose_animation from "./player_files/files3D/before_after_anim01.fbx";


    const componentElementVers = document.querySelector('tamkin-player-sdk');
    let version;
    if (componentElementVers) {
      version = componentElementVers.getAttribute('with-player');
    }
    //import fares_rigging from "./player_files/files3D/tanslation_pose_body_glb.glb";



    import Stats from 'three/addons/libs/stats.module.js';

    import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
    import { FBXLoader } from 'three/addons/loaders/FBXLoader.js';

    import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';


    let charWidth = 0;
    let charHeight = 0;
    
    let componentElement;
    let reeantrant = false;

    let lang;
    let source;
    if (componentElement) {
      lang = componentElement.getAttribute('lang');
    }



    /* let with_player;
     if (componentElement)
     {
     with_player = componentElement.getAttribute('with_player');
     }
     else{
           with_player = 'false';
           //charWidth =500;
           //charHeight =500;
     }*/



    export default {

      state: {
        lang: lang,
        with_player: version,
        with_player: version,
        charWidth: parseInt(charWidth),
        charHeight: parseInt(charHeight),
        isInit : false,
        position : 1,
        character : 'Fares'
      },
      onBeforeMount() {
        // Generate a unique ID for each component instance before it mounts
        this.state.dynamicId = `element-${Date.now()}--${Math.random().toString(36).substr(2, 9)}`;
      },
      createBlob(json) {

        const blob = new Blob([JSON.stringify(json)], { type: 'application/json' });

        // Create a URL object from the Blob
        const url = URL.createObjectURL(blob);
        return url;
      },

      onMounted(props, state) {
        var $this = this;
        if(state.isInit)
        {return false}
        state.isInit = true;
        componentElement = $this.root;
        // console.log(componentElement.style.width, "rrrrrrr")
        // console.log("aaaaaa", componentElement)
       
       $this.state.position = parseInt(props.position ??  '1' );
       $this.state.character = props.character ?? state.character;
        let device;
         var first= 0 ; 
                var size;
        
        device = props.device ;
 

        source = props.source;


        play = true;
        window.mix = [];
        window.Init_actions = [];

        window.loaded = [];
        window.names = [];
 
        let dynamicId = this.state.dynamicId;
        const textureLoader = new THREE.TextureLoader();
        let stream; // Capture at 60 fps if possible
        let recordedChunks = [];
        var scene, camera, renderer, character;
        var i = 0;
        var clock, mixer, actions = {}, activeAction;
        let Character;

        var width = props.charwidth??    0  ;
        var height = props.charheight?? 0;
        const url = new URL(window.location.href);

// If you have a specific URL as a string
// const url = new URL('https://example.com?character=Fares');

// Use URLSearchParams to access query parameters
const params = new URLSearchParams(url.search);

// Get the value of the "character" parameter
var  princ_Char;
if (params.has('character')) {
princ_Char = params.get('character');
;
}       else{
princ_Char = $this.state.character ;
} 
    //      if (componentElement)
    //  {
    //  princ_Char = componentElement.getAttribute('princ_Char');
    //  }
     
    //     princ_Char = princ_Char ? princ_Char : "Fares";
        height = width * 1.3;
        if(!width || width == 0 ){

          width = $($this.root).parent().width() ;
          console.log(width,'parent width')
        }
        
        if(!height || height == 0 ){

          height = $($this.root).parent().height();
          console.log(height,'parent height')

        }


        width = parseInt(width);
        height = parseInt(height);
        
        $($this.root).width(width);
        $($this.root).height(height);

        console.log(width,"width")
        console.log(height,"height")

        function changeLanguage(_lang) {
                  lang = _lang;
                  console.log(lang)
                }
       /* function changeCharacter(character) {
          if(window.reeantrant == true){
            return;
          }
          window.reeantrant = true;
          let ActiveChar = window.ActiveChar;
          // if (character == "Fares" || character =="Sara")
          // {
          //   ActiveChar = 'Qasim'
          // }
          // else if (character == "Qasim" || character =="Sara"){
          //   ActiveChar = 'Fares'

          // }
          // else if (character == "Qasim" || character == "Fares" ){
          //   ActiveChar = 'Sara'

          // }

          console.log(ActiveChar);
          const retrievedACharacter = scene.getObjectByName(ActiveChar);
          console.log(retrievedACharacter, "aaaaa")
         // removeCarater(retrievedACharacter);
          const retrievedCharacter = scene.getObjectByName(character);

          if (!retrievedCharacter) {
            if (character == "Qasim") {

              loadCharacter(qassem_rigging, "Qasim", function (loadedCharacter) {
                window.character = loadedCharacter;
                window.ActiveChar = "Qasim";

          adjustCameraBasedOnCharacter(post, width, height);
          console.log("character changed");   
              default_outfit();
           });


            }
            else if (character == "Fares") {
              loadCharacter(fares_rigging, "Fares", function (loadedCharacter) {
                window.character = loadedCharacter;
                window.ActiveChar = "Fares";
    default_outfit();
          adjustCameraBasedOnCharacter(post, width, height);
          console.log("character changed");
              });
            }
            else if (character == "Sara") {
              loadCharacter(sarra_rigging, "Sara", function (loadedCharacter) {
                window.character = loadedCharacter;
                window.ActiveChar = "Sara";
                    default_outfit();
          adjustCameraBasedOnCharacter(post, width, height);
          console.log("character changed");
              });
            }
          else if (character == "Saeed") {
              loadCharacter(saaied_rigging, "Saeed", function (loadedCharacter) {
                window.character = loadedCharacter;
                window.ActiveChar = "Saeed";
                    default_outfit();
          adjustCameraBasedOnCharacter(post, width, height);
          console.log("character changed");
              });
            }




          }
          else {
            window.reeantrant = true;
            retrievedCharacter.visible = true
            window.ActiveChar = character;
            console.log(window.mix[character]);
            window.mixer = window.mix[character]

            firstanimation(window.mixer)


            console.log(window.mixer);
          }

          default_outfit();
          adjustCameraBasedOnCharacter(post, width, height);
          console.log("character changed");
          window.reeantrant=false;
        }*/
    async function changeCharacter(character) {
    // Prevent concurrent executions

    $this.state.character = character ?? $this.state.character;
    character = $this.state.character;
    
    if (window.reeantrant === true) {
        return;
    }
    window.reeantrant = true;

    try {
       if (window.flutter_inappwebview) {
              window.flutter_inappwebview.callHandler('characterLoadStarted');
            }
        let activeChar = window.ActiveChar;

        // Retrieve and remove the currently active character
        if (activeChar) {
            const retrievedACharacter = scene.getObjectByName(activeChar);
                scene.remove(retrievedACharacter);

            if (retrievedACharacter) {
                removeCarater(retrievedACharacter);
            }
        }

        // Retrieve the new character
        let retrievedCharacter = scene.getObjectByName(character);

        if (!retrievedCharacter) {
            // Character not loaded, load it asynchronously
            let rigging, name;
            console.log(character);
            if (character === "Qasim") {
                rigging = qassem_rigging;
                name = "Qasim";
            } else if (character === "Fares") {
                rigging = fares_rigging;
                name = "Fares";
            } else if (character === "Sara") {
                rigging = sarra_rigging;
                name = "Sara";
            } else if (character === "Saeed") {
                rigging = saaied_rigging;
                name = "Saeed";
            } else {
                throw new Error("Unknown character: " + character);
            }

            await new Promise((resolve) => {
                loadCharacter(rigging, name, function (loadedCharacter) {
                    window.character = loadedCharacter;
                    window.ActiveChar = name;
                    default_outfit();

                    adjustCameraBasedOnCharacter($this.state.position, width, height);
                    resolve();
                });
            });
        } else {
            // If the character is already loaded, make it visible
            console.log('******')
            retrievedCharacter.visible = true;
            window.ActiveChar = character;
            window.mixer = window.mix[character];
            firstanimationRetr(window.mixer,character);
        }

        // Finalize character switch
        default_outfit();
        adjustCameraBasedOnCharacter($this.state.position, width, height);
        console.log("Character changed successfully");
           if(window.flutter_inappwebview)
      {
        window.flutter_inappwebview.callHandler('characterLoadFinished');
      }
      return true;
    } catch (error) {
        console.error("Error changing character:", error);
    } finally {
        // Always reset the reentrancy flag
        window.reeantrant = false;
    }
}
        function setwidthAndheigh(newWidth,newHeight) {
          width = newWidth;
          height = newHeight;
            renderer.setSize(newWidth, newHeight);

        }

     
        function adjustCameraBasedOnCharacter(position,newWidth,newHeight) {
            
           $this.state.position = position;

             width = newWidth;
             height = newWidth * 1.3; 
 
            renderer.setSize(newWidth, newHeight);
          
          camera = null
          camera = new THREE.PerspectiveCamera(50, (width / height), 1, 15);

          camera.position.set(0, 1.13, 2);
          camera.updateProjectionMatrix();

          window.character.position.x = -0.05
          window.character.position.y = -1.4

          console.log(width,height)


          if (position == 1) { 
            if (window.ActiveChar== "Sara"){
if(width<400)
              {
                  window.character.scale.set(0.00009 * width, 0.020, 0.007); 
                  window.character.position.y = -1.5
              } 
              else if (width>=400 && width<800){
                   window.character.scale.set(0.000038 * width, 0.02, 0.007);  
              }
              else if (width>=800 && width<1200){
                  window.character.scale.set(0.000020 * width, 0.020, 0.007);  
              }
              else{
                   window.character.scale.set(0.000015 * width, 0.019, 0.007);
                  window.character.position.y = -1.2

              }  
            }
            else{
              if(width<400)
              {
                  window.character.scale.set(0.00007 * width, 0.020, 0.007); 
                  window.character.position.y = -1.5
              } 
              else if (width>=400 && width<800){
                   window.character.scale.set(0.000035 * width, 0.02, 0.007);  
              }
              else if (width>=800 && width<1200){
                  window.character.scale.set(0.000018 * width, 0.020, 0.007);  
              }
              else{
                   window.character.scale.set(0.000012 * width, 0.019, 0.007);
                  window.character.position.y = -1.2

              }  
            }
          } 
          if (position == 2) { 
             window.character.position.y = 0.3;
              if(width<400)
              {
                  window.character.scale.set(0.000075 * width, 0.010, 0.007);  
              } 
              else if (width>=400 && width<800){
                  window.character.scale.set(0.000025 * width, 0.0096, 0.007); 
              }
              else if (width>=800 && width<1200){
                  window.character.scale.set(0.000012 * width, 0.0096, 0.007); 
              }
              else{
                  window.character.scale.set(0.000008 * width, 0.0096, 0.007);  
              }  
          }
          
          else if (position == 3) { 
             window.character.position.y = 0.3;
              if(width<400)
              {
                  window.character.scale.set(0.00007 * width, 0.015, 0.007);  
              } 
              else if (width>=400 && width<800){
                  window.character.scale.set(0.000035 * width, 0.015, 0.007);   
              }
              else if (width>=800 && width<1200){
                  window.character.scale.set(0.000018 * width, 0.015, 0.007);   
              }
              else{
                  window.character.scale.set(0.000012 * width, 0.015, 0.007);  

              }  
          } 

/*

          else if (position == 2) {
            
             var bbox ;
           if(first==0){
              bbox = new THREE.Box3().setFromObject(window.character);

                size = new THREE.Vector3();
              bbox.getSize(size);
              first++;
           }
            // Calculate a suitable distance of the camera from the character
            // This is a bit arbitrary and depends on your scene scale and FOV
            var distance = size.y * 2.5;

            // Adjust camera position. The Y position is set to half the character's height
            // to aim at the middle of the character. Adjust Z to move the camera back
            // a distance calculated above. X is set to 0 assuming the character is centered.
            camera.position.set(0, size.y / 1.5, distance);

            // Adjust the camera's near and far planes to avoid clipping
            camera.near = distance * 0.1;
            camera.far = distance * 10;
            window.cha.scale.set(width * 0.00013, 0.04, 0.007);
          
            camera.updateProjectionMatrix();
                                          //window.cha.scale.set(0.0119, 0.0119, 0.0119);
              window.character.position.set(-(width * 0.0025), -1, 0);
            // If using OrbitControls or similar, you might want to update the target
           
            if (controls) {
              controls.target.set(0, size.y / 3.8, 0);
              controls.update();
            }




          }

          */


        }

        function mountAll() {
          
riot.mount("tamkin-sdk-web-character");
//riot.mount("*");

        }
  console.log("version 0.1.1")
        function changeBackgroundColor(color) {
          if (color == "") {
            scene.background = null;

          }
          else {

            scene.background = new THREE.Color(color); // Replace 0x000000 with your desired color code
          }

        }


        function changeBackgroundImage(urlImage) {
          if (urlImage == "") {
            scene.background = null;

          }
          else {
            const loader = new THREE.TextureLoader();
            loader.load(urlImage, function (texture) {
              scene.background = texture;
            });
          }
        }
       function removeCarater(retrievedCharacter) {
  /*if (retrievedCharacter) {
    console.log(retrievedCharacter, 'Removing character from scene');

    // Stop all actions for the character's mixer
    const mixer = window.mix[retrievedCharacter];
    if (mixer) {
      mixer.stopAllAction();
      console.log('Stopped all actions for mixer:', retrievedCharacter.name);
    }

  
    // Make the character invisible
    retrievedCharacter.visible = false;

    // Reset global mixer reference if this was the active character
    if (window.ActiveChar === retrievedCharacter) {
      window.mixer = null;
      console.log('Reset global mixer reference for:', retrievedCharacter.name);
    }
  }*/
      // deleteCharacter(retrievedCharacter);

}
function deleteCharacter(characterName) {
  const character = scene.getObjectByName(characterName);

  if (character) {
    console.log(`Deleting character: ${characterName}`);

    // Remove from scene
    scene.remove(character);

    // Dispose of geometry and materials
    character.traverse((node) => {
      if (node.isMesh) {
        if (node.geometry) {
          node.geometry.dispose();
        }
        if (node.material) {
          // Dispose of materials
          if (Array.isArray(node.material)) {
            node.material.forEach((material) => {
              if (material.map) material.map.dispose();
              if (material.lightMap) material.lightMap.dispose();
              if (material.bumpMap) material.bumpMap.dispose();
              if (material.normalMap) material.normalMap.dispose();
              if (material.specularMap) material.specularMap.dispose();
              if (material.envMap) material.envMap.dispose();
              material.dispose();
            });
          } else {
            if (node.material.map) node.material.map.dispose();
            if (node.material.lightMap) node.material.lightMap.dispose();
            if (node.material.bumpMap) node.material.bumpMap.dispose();
            if (node.material.normalMap) node.material.normalMap.dispose();
            if (node.material.specularMap) node.material.specularMap.dispose();
            if (node.material.envMap) node.material.envMap.dispose();
            node.material.dispose();
          }
        }
      }
    });

    // Remove associated animation mixer
    if (window.mix[characterName]) {
      const mixer = window.mix[characterName];
      mixer.stopAllAction();
      delete window.mix[characterName]; // Remove from global mixers
      console.log(`Animation mixer for ${characterName} removed.`);
    }

    // Clean up global references
    if (window.ActiveChar === characterName) {
      window.mixer = null;
      window.ActiveChar = null;
    }

    console.log(`${characterName} successfully deleted.`);
  } else {
    console.warn(`Character "${characterName}" not found in the scene.`);
  }
}

        function without_outfit() {
          const retrievedACharacter = scene.getObjectByName(window.ActiveChar);

          retrievedACharacter.traverse(function (node) {
            if (node.name.includes("clothes") || node.name.includes("CLOTHES")) {

              node.visible = false;


            }
          });
        }
        function default_outfit() {

          window.character.traverse(function (node) {
            if (node.name.includes("clothes")) {
              if (!node.name.includes("fares_clothes_orignal") && !node.name.includes("fares_clothes_original")
                && !node.name.includes("sara_clothes_origna") && !node.name.includes("saaied_clothes_original")
                // node.name !== "clothes_shirt_purple"fares_clothes_original_glasses_0016
              ) {
                node.visible = false;
              }
              else {
                node.visible = true;
              }

            }
          });
        }
        function initiatePlayer() {
          setTimeout(() => {
            if (window.characterLoadStarted) {
              window.characterLoadStarted();
            }
            if (window.flutter_inappwebview) {
              window.flutter_inappwebview.callHandler('characterLoadStarted');
            }
          }, 10);

      
          init(dynamicId, function (loadedCharacter) {
            state.isInit = true
            animate();
            window.character = loadedCharacter;

            const allClothesNames = getClothesNames(loadedCharacter);
            adjustCameraBasedOnCharacter($this.state.position,width,height);
               //adjustCameraBasedOnCharacter(2,1.5,0,1,2);

            /* if(width !== 0)
             {
               adjustCameraBasedOnCharacter(2.5,1.5,0,1,2);
             }*/
            default_outfit();

            // console.log("car");
            // changeCharacter("qassem")
            // console.log("car1");

          });

        


          //   const allClothesNames = getClothesNames(loadedCharacter);
          //   console.log(allClothesNames);

          // loadCharacter(qassem_rigging, function(loadedCharacter) {
          //                callback(loadedCharacter);
          //           });

        }



        function changeSpeed(speed) {
          console.log( window.action)
                    console.log(  window.action.timeScale)
                    
          if( window.action){
                     window.action.timeScale = speed; 
                     window.speed = speed;

          }


        }

        function init(idContainner, callback) {
          var element = document.getElementById("player-card");
          var container = $this.$(".tamkin-three-container");

          //var width = window.innerWidth ;
          //var height = window.innerHeight ;
        
          console.log("width", width, "height", height);
          // if (element) {
          //     var rect = element.getBoundingClientRect();
          //     width = rect.width;
          //     height = rect.height;

          // } 


          scene = new THREE.Scene();
          renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
          
          renderer.setSize(width, height);

          renderer.shadowMap.enabled = true;

          //$this.root.appendChild(renderer.domElement);
          container.appendChild(renderer.domElement);


          //renderer.setClearColor(0x000022, 0);
          clock = new THREE.Clock();
          //scene.fog = new THREE.Fog( 0xa0a0a0, 10, 50 );

          const hemiLight = new THREE.HemisphereLight(0xffffff, 0x8d8d8d, 3);
          hemiLight.position.set(0, 20, 0);
          scene.add(hemiLight);
          const dirLight = new THREE.DirectionalLight(0xffffff, 1);
          dirLight.position.set(3, 10, 10);
          dirLight.castShadow = true;
          dirLight.shadow.camera.top = 2;
          dirLight.shadow.camera.bottom = - 2;
          dirLight.shadow.camera.left = - 2;
          dirLight.shadow.camera.right = 2;
          dirLight.shadow.camera.near = 0.1;
          dirLight.shadow.camera.far = 40;
          scene.add(dirLight);
          let c;
          let rigging ;
        
    
    

          console.log(princ_Char, "princ_Char")


          if(princ_Char =="Fares")
          {rigging = fares_rigging}
          else if(princ_Char =="Sara")
          {
            rigging = sarra_rigging
          }
           else if(princ_Char =="Qasim")
          {
            rigging = qassem_rigging
          }
           else if(princ_Char =="Saeed")
          {
            rigging = saaied_rigging
          }
          else{
                        rigging = fares_rigging

          }
       
          loadCharacter(rigging, princ_Char, function (loadedCharacter) {
            callback(loadedCharacter);
          });
          

          var light = new THREE.AmbientLight(0xffffff);
          scene.add(light);

         
          camera = new THREE.PerspectiveCamera(10, (width / height), 2, 30);

          
          camera.position.set(-10, 1, 2);
          camera.zoom = 1.5;
          camera.updateProjectionMatrix();
          controls = new OrbitControls(camera, renderer.domElement);
          controls.enablePan = false;
          controls.enableZoom = false;
          controls.target.set(0, 1, 0);
          controls.enabled = false;
          controls.update();


          // Load the character model first
          return (c);

        }

        function showClothesVisibility(name) {
          let ch = window.ActiveChar;
          const retrievedACharacter = scene.getObjectByName(ch);

          retrievedACharacter.traverse((child) => {
            if (child.name.toLowerCase() === name.toLowerCase()) {

              child.visible = true;
            }
          });
        }
        function hideClothesVisibility(name) {
          let ch = window.ActiveChar;
          const retrievedACharacter = scene.getObjectByName(ch);
          retrievedACharacter.traverse((child) => {
            if (child.name.toLowerCase() === name.toLowerCase()) {

              child.visible = false;
            }
          });
        }
        function getClothesNames(character) {
          let clothesNames = [];

          character.traverse(function (child) {
            // Here, you might want to include some logic to determine whether the child is considered 'clothes'
            // This could be based on naming conventions, specific object properties, or layers
            if (child.isMesh) {
              clothesNames.push(child.name);
            }
          });


          return clothesNames;
        }

        const animationJSONPath = 'https://api.tamkin.app/v1/api/Ai/GetAnimation';

        const expressionsJSONPath = 'https://api.tamkin.app/v1/api/Tamkin Expressions/Get';

 

let currentMixer = null; // Global reference for the current mixer
let isAnimating = false; // Flag to track if animations are in progress
let animationAbortController = null; // Abort controller to cancel fetch requests
let previousAction = null; // Keep track of the previously played action for smooth crossfade

let ax = false; // Initialize ax to false

let pendingAnimationParams = null; // Variable to store pending animation parameters

async function fnAnimateText(text, lang , speed) {
  changeLanguage(lang);
  return getAdAnimate(text, speed ?? 1,  5);
}
async function getAdAnimate(text, speed=1, batchSize = 5) {
    console.log("getAdAnimate called with text:", text, "speed:", speed);
    ax = false;
    window.speed = speed;

    // If already animating, interrupt the current animation sequence
    if (isAnimating) {
        console.log("Interrupting current animation sequence...");

        // Store the new animation parameters
        pendingAnimationParams = { text, speed, batchSize };

        // Abort the current fetch request
        if (animationAbortController) {
            animationAbortController.abort();
        }

        // Reset the animation state
        window.state_animation = null;

        if (window.action && Init_action) {
            // Crossfade smoothly back to Init_action instead of abruptly resetting
            window.action.fadeOut(0.5);
            Init_action.fadeOut(0.5);
            Init_action.reset().play();
        } else if (Init_action) {
            // If no active animation, simply play Init_action
            Init_action.fadeOut(0.5).play();
        }

        console.log("Animation sequence interrupted, transitioned to Init_action.");

        // Set isAnimating to false to allow the new animation to start
        isAnimating = false;

        // Exit the function; the catch block will handle the pending animation
        //return;
    }

    const load = new GLTFLoader();
    animationAbortController = new AbortController(); // Create a new abort controller

    try {
        isAnimating = true; // Set animating flag

        // Notify the running state
        if (window.onRunning) {
            window.onRunning();
        }
        if (window.flutter_inappwebview) {
            window.flutter_inappwebview.callHandler('onRunning');
        }

        // Fetch the animation data
        console.log("Fetching animation data from API...");
        const response = await fetch(animationJSONPath, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': '*/*'
            },
            body: JSON.stringify({ text, lang }),
            signal: animationAbortController.signal
        });

        console.log("Animation data fetched successfully.");
        const animationData = await response.json();
        const animations = animationData.data;
        if (animations.length === 0) {
          console.log("No animations found.");
            if (window.flutter_inappwebview) {
                window.flutter_inappwebview.callHandler('onAnimFinished');
                window.flutter_inappwebview.callHandler('onFinished');

            }


         

            isAnimating = false;
            window.state_animation = "Finished";
          return;
        }
        // Split animations into batches
        const batches = [];
        for (let i = 0; i < animations.length; i += batchSize) {
            batches.push(animations.slice(i, i + batchSize));
        }

        let currentBatchIndex = 0;

   function loadBatch(batch, login, password) {
    console.log(`Loading batch ${currentBatchIndex + 1}/${batches.length}:`, batch);
    
    // Generate the decryption key from login and password
    const decryptionKey = "foo123";
    
    return Promise.all(
        batch.map(zipFilename => {
            // Replace '_animation/files' with '_animation/fileszip' in the zipFilename
            const updatedZipFilename = zipFilename.replace('_animation/files', '_animation/fileszip');
            const zipUrl = `https://api.tamkin.app/${updatedZipFilename}`;
            console.log(`Fetching ZIP from: ${zipUrl}`);

            return fetch(zipUrl)
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`Failed to fetch ${zipFilename}: ${response.statusText}`);
                    }
                    return response.blob(); // Fetch as Blob
                })
                .then(blob => {
                    return new Promise((resolve, reject) => {
                        // Create a ZipReader with the Blob
                        const zipReader = new zip.ZipReader(new zip.BlobReader(blob));

                        // Get all entries (files) in the ZIP
                        zipReader.getEntries().then(entries => {
                            if (entries.length === 0) {
                                zipReader.close().then(() => {
                                    reject(new Error('No entries found in the ZIP file.'));
                                });
                                return;
                            }

                            // Filter out directories and select .glb/.gltf files
                            const gltfEntries = entries.filter(entry => {
                                return !entry.directory && (entry.filename.endsWith('.gltf') || entry.filename.endsWith('.glb'));
                            });

                            if (gltfEntries.length === 0) {
                                zipReader.close().then(() => {
                                    reject(new Error('No .glb or .gltf files found in the ZIP.'));
                                });
                                return;
                            }

                            // Load all GLTF files
                            const loadPromises = gltfEntries.map(entry => {
                                return entry.getData(new zip.BlobWriter(), {
                                    password: decryptionKey // Provide the decryption key here
                                }).then(decryptedBlob => {
                                    return new Promise((resolve, reject) => {
                                        const url = URL.createObjectURL(decryptedBlob);
                                        load.load(
                                            url,
                                            gltf => {
                                                console.log(`Loaded animation: ${entry.filename}`);
                                                // Add the loaded GLTF model to the scene
                                                scene.add(gltf.scene);
                                                resolve(gltf);
                                                URL.revokeObjectURL(url); // Clean up
                                            },
                                            undefined,
                                            error => {
                                                console.error(`Error loading animation ${entry.filename}:`, error);
                                                reject(error);
                                                URL.revokeObjectURL(url); // Clean up
                                            }
                                        );
                                    });
                                }).catch(err => {
                                    console.error(`Failed to extract ${entry.filename}:`, err);
                                    throw err;
                                });
                            });

                            // Wait for all GLTF files to load
                            Promise.all(loadPromises)
                                .then(gltfResults => {
                                    resolve(gltfResults);
                                    zipReader.close(); // Close the ZIP reader
                                })
                                .catch(error => {
                                    zipReader.close().then(() => {
                                        reject(error);
                                    });
                                });
                        }).catch(err => {
                            zipReader.close().then(() => {
                                reject(err);
                            });
                        });
                    });
                })
                .catch(error => {
                    console.error(`Error processing ${zipFilename}:`, error);
                    throw error; // Propagate the error to Promise.all
                });
        })
    )
    .then(results => {
        // Flatten the array if each batch returns an array of animations
        return results.flat();
    })
    .catch(error => {
        console.error('Error loading batch:', error);
        throw error; // Propagate the error if needed
    });
}
        console.log("here")
        // Start loading the first batch
        let currentBatchPromise = loadBatch(batches[currentBatchIndex]);
        console.log(isAnimating,"here")
isAnimating = true
        while (currentBatchIndex < batches.length && isAnimating) {
            // Wait for the current batch to load fully
            console.log(`Awaiting batch ${currentBatchIndex + 1} to load...`);
            const gltfModels = await currentBatchPromise;

            // Check if a new animation was requested during loading
            if (!isAnimating) {
                console.log("Animation sequence interrupted during batch loading.");
                break;
            }

            // Preload the next batch if it exists
            let nextBatchPromise = null;
            if (currentBatchIndex + 1 < batches.length) {
                nextBatchPromise = loadBatch(batches[currentBatchIndex + 1]);
            }

            // Animate the current batch sequentially
            for (let i = 0; i < gltfModels.length; i++) {
                // If interrupted, stop immediately
                if (!isAnimating) {
                    console.log("Animation sequence interrupted during playback.");
                    break;
                }

                console.log(`Playing animation ${i + 1} of batch ${currentBatchIndex + 1}`);
                await playSmoothAnimation(gltfModels[i], speed);
            }

            currentBatchIndex++;
            if (nextBatchPromise) {
                currentBatchPromise = nextBatchPromise;
            }
        }

        if (isAnimating) {
            console.log("All batches played successfully.");
            if (window.onFinished) {
                window.onFinished();
                console.log("All animations finished.");
            }
            if (window.flutter_inappwebview) {
                window.flutter_inappwebview.callHandler('onFinished');
            }


            Init_action.fadeIn(0.17);

            Init_action.reset().play();
                                 window.action.fadeOut(0.17);

            isAnimating = false;
            window.state_animation = "Finished";

          
            console.log("Returned to Init_action.");
        }

    } catch (error) {
        if (error.name === "AbortError") {
            console.log("Animation fetching aborted.");

            // After aborting, check if there are pending animation parameters
            if (pendingAnimationParams) {
                const { text: newText, speed: newSpeed, batchSize: newBatchSize } = pendingAnimationParams;
                console.log("Starting new animation after aborting previous one.");
                // Clear the stored parameters to prevent repeated calls
                pendingAnimationParams = null;
                // Start the new animation
                getAdAnimate(newText, newSpeed, newBatchSize);
            }
        } else {
            console.error('Error occurred while fetching or loading animations:', error);
        }
        isAnimating = false; // Ensure flag is reset on error

        // Reset to Init_action if necessary
        if (Init_action) {
            Init_action.reset().play();
            console.log("Init_action played after error.");
        }
    }
}




// Assuming Init_action is defined and initialized elsewhere
let Init_action; // Initialize this with your initial animation action

// Play all animations in a gltf smoothly, one after another
function playSmoothAnimation(gltf, speed) {
  return new Promise((resolve) => {
    currentMixer = window.mixer || new THREE.AnimationMixer(gltf.scene);
    const animationClips = gltf.animations;
    let currentIndex = 0;

    function playNextClip() {
      // If all animations for this model have been played, resolve the promise.
      if (currentIndex >= animationClips.length) {
        resolve();
        return;
      }

      const clip = animationClips[currentIndex];
      const currentAction = currentMixer.clipAction(clip);

      currentAction.timeScale = window.speed;
      currentAction.loop = THREE.LoopOnce;
      currentAction.clampWhenFinished = true;
      window.action = currentAction;
      const fadeDuration = 0.3;
      if (window.state_animation !== "Running") {
        window.state_animation = "Running";
        if (window.flutter_inappwebview) {
            window.flutter_inappwebview.callHandler('onAnimFinished');
        }
      }

      setTimeout(() => {
        if (Init_action && !ax) {
          console.log("Init_action");
          console.log("Stopping Init_action");
          Init_action.fadeOut(0.3);

          // Init_action.stop()

          ax = true;
        }
      }, 180);

      if (previousAction) {
        currentAction.play();
        crossfade(previousAction, currentAction, fadeDuration);
      } else {
        currentAction.reset().play();
      }

      currentMixer.addEventListener('finished', function onFinished(e) {
        if (e.action === currentAction) {
          currentMixer.removeEventListener('finished', onFinished);

          // Notify that the current animation finished
          
          currentAction.fadeOut(0.3);
          previousAction = currentAction;
          currentIndex++;
          playNextClip();
        }
      });
    }

    playNextClip();
  });
}

// Ensure you have the crossfade function defined
function crossfade(fromAction, toAction, duration) {
  fromAction.crossFadeTo(toAction, duration, false);
}


// Add state to track the expression animation
window.expressionState = {
  isRunning: false,
  currentExpression: null,
};

// Fetch, retrieve animation file, and play the expression animation
async function getExpression(expressionName, speed = 1) {
  if (window.expressionState.isRunning) {
    console.log("Expression already running. Interrupting...");
    stopExpression();
  }

  try {
  const response = await fetch("https://api.tamkin.app/v1/api/Tamkin Expressions/Get", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name: expressionName }), // Send the name as a parameter
    });
        const data = await response.json();

    if (!data || !data.succeeded || !data.data || data.data.length === 0) {
      console.error("No expressions found in the API response.");
      return;
    }

    // Search for the expression by name
    const expression = data.data.find((expr) => expr.name === expressionName);

    if (!expression) {
      console.error(`Expression with name "${expressionName}" not found.`);
      return;
    }

    const animationFile = expression.animation_file;

    if (!animationFile) {
      console.error(`No animation file found for expression "${expressionName}".`);
      return;
    }

    // Set the expression state
    window.expressionState.isRunning = true;
    window.expressionState.currentExpression = expressionName;

    // Stop the initial animation
   
    // Load and play the expression animation
    await loadAndPlayExpressionAnimation(`https://api.tamkin.app/_animation${animationFile}`, speed);

    // Notify when finished
firstanimation();
    console.log(`Expression animation "${expressionName}" finished.`);
    window.expressionState.isRunning = false;
    window.expressionState.currentExpression = null;
    Init_action=null;
      firstanimation(window.mixer);

    if (window.onExpressionFinished) {
      window.onExpressionFinished(expressionName);
    }
    if (window.flutter_inappwebview) {
      window.flutter_inappwebview.callHandler("onExpressionFinished", expressionName);
    }

  } catch (error) {
    console.error("Error fetching or playing expression:", error);
    window.expressionState.isRunning = false;
    window.expressionState.currentExpression = null;
  }
}

// Stop the current expression animation
function stopExpression() {
  if (window.expressionState.isRunning) {
    console.log("Stopping current expression animation...");
    window.mixer.stopAllAction();
    window.expressionState.isRunning = false;
    window.expressionState.currentExpression = null;

    // Resume the initial animation
    if (Init_action) {
      Init_action.reset().play();
    }
  }
}

// Load and play a GLTF animation for expressions
async function loadAndPlayExpressionAnimation(animationFile, speed = 1) {
  return new Promise((resolve, reject) => {
    const loader = new GLTFLoader();

    loader.load(
      animationFile,
      (gltf) => {
        const mixer = window.mixer || new THREE.AnimationMixer(gltf.scene);
        const clip = gltf.animations[0];

        if (!clip) {
          console.error("No animation found in the loaded GLTF.");
          resolve();
          return;
        }
        if (Init_action) {
      Init_action.fadeOut(0.5);
      Init_action.stop();
    }

        const action = mixer.clipAction(clip);
        action.timeScale = speed;
        action.loop = THREE.LoopOnce;
        action.clampWhenFinished = true;

        action.reset().play();

        mixer.addEventListener("finished", () => {
          mixer.removeEventListener("finished", this);
          resolve();
        });

        window.mixer = mixer;
      },
      undefined,
      (error) => {
        console.error("Error loading expression animation:", error);
        reject(error);
      }
    );
  });
}

// Crossfade function for smooth transitions between actions
function crossfade(fromAction, toAction, duration) {
  fromAction.fadeOut(duration);
  toAction.reset().fadeIn(duration).play();
}

function firstanimation(mixerInstance,action) {
  let pose_animation;

  const load = new GLTFLoader();
  console.log(window.ActiveChar)
  // Determine the animation based on the active character
  if (window.ActiveChar === "Fares") {
    pose_animation = pose_animation_Fares;
  } else if (window.ActiveChar === "Sara") {
    pose_animation = pose_animation_Fares;
  } else if (window.ActiveChar === "Qasim") {
    pose_animation = pose_animation_Fares;

  } else if (window.ActiveChar === "Saeed") {
    pose_animation = pose_animation_Fares;
  } else{
    pose_animation = pose_animation_Fares;
  }
  
  if (Init_action ) {
    Init_action.stop();
   // Init_action = null;
    //crossfade(currentAction, Init_action, 0.5);
   //return ;
  }
  
  load.load(pose_animation, (gltf) => {
    console.log(mixer)
    if (gltf.animations && gltf.animations.length > 0) {
      Init_action = mixer.clipAction(gltf.animations[0]);
            window.Init_actions[window.ActiveChar] = Init_action;

      console.log(window.ActiveChar)
      Init_action.reset().play();
    } else {
      console.warn("No animations found in the loaded GLTF model.");
    }
  });
  
}

function firstanimationRetr(mixerInstance,char) {
   mixer.update(delta);
  window.Init_actions[char].stop()
    window.Init_actions[char].play()

  
}
function firstanimationStop() {
  
      Init_action.reset().stop();

  
}
// Crossfade function for smooth transitions between actions
function crossfade(fromAction, toAction, duration) {
  fromAction.fadeOut(duration);
  toAction.reset().fadeIn(duration).play();
}
function stopAllAnimations() {
  if(Init_action){

  window.mixer.stopAllAction();
  Init_action.play(); 
  }
}

function firstanimation2(mixerInstance) {
  let pose_animation;

  const load = new GLTFLoader();

  // Determine the initial pose animation based on the active character
  if (window.ActiveChar === "Fares") {
    pose_animation = pose_animation_Fares;
  } else if (window.ActiveChar === "Sara") {
    pose_animation = pose_animation_Sara;
  } else if (window.ActiveChar === "Qasim") {
    pose_animation = pose_animation_Qassim;
  } else if (window.ActiveChar === "Saeed") {
    pose_animation = pose_animation_Saeed;
  }

  // If we already have Init_action, no need to reload
  // Just ensure we have the mixer and action ready
  if (Init_action && mixerInstance) {
    // We already have the initial pose loaded
    // Just crossfade to it when needed
    return;
  }

  // Load and play the initial pose animation
  load.load(pose_animation, (gltf) => {
    const mixer = mixerInstance || new THREE.AnimationMixer(gltf.scene);

    if (gltf.animations && gltf.animations.length > 0) {
      Init_action = mixer.clipAction(gltf.animations[0]);
      Init_action.loop = THREE.LoopOnce;
      Init_action.clampWhenFinished = true;
      Init_action.reset().play();
    } else {
      console.warn("No animations found in the loaded GLTF model for the initial pose.");
    }
  });
}

// Example usage in your getAdAnimate logic:
// 1. Start by calling firstanimation at the very beginning of your scene setup:
// firstanimation(window.mixer);

// 2. When you start sign language animations, crossfade from Init_action to the first sign anim action:
function startSignLanguageAnimation(firstSignAction) {
  // Assume previousAction = Init_action at this point
  if (Init_action && previousAction === Init_action) {
    crossfade(Init_action, firstSignAction, 0.3);
  } else {
    // If for some reason Init_action isn't previousAction, just play directly
    firstSignAction.reset().play();
  }
  previousAction = firstSignAction;
}

// 3. After finishing all sign language animations, call firstanimation again and crossfade back:
async function onAllSignAnimationsFinished() {
  // Re-invoke firstanimation if needed to ensure Init_action is set
  firstanimation(window.mixer);

  // Wait a moment for Init_action to be ready if it's not already
  // If Init_action is already loaded and played once, we can just crossfade
  if (previousAction && Init_action && Init_action !== previousAction) {
    crossfade(previousAction, Init_action, 1);
    previousAction = Init_action;
  }
}

// Crossfade function remains the same
function crossfade(fromAction, toAction, duration) {
  fromAction.fadeOut(duration);
  toAction.reset().fadeIn(duration).play();
}



        function replay() {
          console.log(window.action);
          window.action.reset().play();
        }



   const blobCache = new Map(); // In-memory cache for blobs

const characterCacheName = 'character-cache';

async function loadCharacter(url, name, callback) {
  window.reeantrant = true;

  // Check Cache Storage first
  const cache = await caches.open(characterCacheName);
  const cachedResponse = await cache.match(url);
  if (cachedResponse) {
    console.log(`Loading character ${name} from Cache Storage.`);
    const blob = await cachedResponse.blob();
    const cachedObjectURL = URL.createObjectURL(blob);

    var loader = new FBXLoader();
    console.log(window.innerWidth, " ", window.innerHeight);
    var lad = new GLTFLoader();
    var shirtT;
    var obj;

    loader.load(cachedObjectURL, function (object1) {
      let obj = object1;
      object1.scale.set((600 / 100000) + 0.01, 0.014, 0.007);
      object1.position.x = -0.07;
      object1.position.y = -0.8;

      window.cha = object1;

      mixer = new THREE.AnimationMixer(object1);
      window.mix[name] = mixer;
      console.log(window.mix[name]);
      object1.name = name;
      window.ActiveChar = name;
      window.mixer = mixer;
      scene.add(object1);
      window.loaChar = 0;

      obj.traverse(function (node) {
        if (node.name == "Main") {
          node.visible = false;
        }

        if (node.name.includes("clothes")) {
          if (
            node.name !== "fares_clothes_tie1_blueblack" &&
            node.name !== "fares_clothes_orignal_pants_blueblack" &&
            node.name !== "fares_clothes_orignal_shirt_aquamarine" &&
            node.name !== "fares_clothes_orignal_shoes_Havana"
          ) {
            node.visible = false;
          }
        }
      });


      window.loaded[name] = true;
  
      firstanimation(window.mix[name],"change");
   setTimeout(() => {
    window.reeantrant = false;
    window.loaChar = 1;
    console.log("loaded char");
    hideLoaders();
}, 150);
      if (callback) {
        callback(obj); // Invoke the callback with the cached object
      }
    });

    return; // Exit after loading from cache
  }

  var loader = new FBXLoader();
  console.log(window.innerWidth," ",window.innerHeight); 
  var lad = new GLTFLoader();
  var shirtT;
  var obj;
  const workerScript = `
 self.addEventListener('message', e => {
   const urlsToFetch = e.data; // Array of URLs or criteria to determine URLs
   // Example: Directly send back the received URLs or modify as needed
   self.postMessage(urlsToFetch);
   // Add any fetching or processing logic here
 });
 `;
  const blob = new Blob([workerScript], { type: 'application/javascript' });
  const workerUrl = URL.createObjectURL(blob);

  const worker = new Worker(workerUrl);

  worker.onmessage = function (e) {
    const objectURL = e.data; // URLs fetched or determined by the worker

    loader.load(objectURL, async function (object1) {
      let obj = object1;
      object1.scale.set((600 / 100000) + 0.01, 0.014, 0.007);
      object1.position.x = -0.07;
      object1.position.y = -0.8;

      window.cha = object1;

      mixer = new THREE.AnimationMixer(object1);
      window.mix[name] = mixer;
      console.log(window.mix[name],4);
      object1.name = name;
      window.ActiveChar = name;
      window.mixer = mixer;
      scene.add(object1);
      window.loaChar = 0

      obj.traverse(function (node) {
        if (node.name == "Main") {
          node.visible = false;
        }

        if (node.name.includes("clothes")) {
          if (
            node.name !== "fares_clothes_tie1_blueblack" &&
            node.name !== "fares_clothes_orignal_pants_blueblack" &&
            node.name !== "fares_clothes_orignal_shirt_aquamarine" &&
            node.name !== "fares_clothes_orignal_shoes_Havana"
          ) {
            node.visible = false;
          }
        }
      });


      window.loaded[name] = true;
      window.loaChar = 1;
      console.log("loaded char");
      hideLoaders();

      firstanimation(window.mix[name],"change");
      URL.revokeObjectURL(workerUrl);
      
      window.reeantrant = false;

      if (callback) {
        callback(obj); // Invoke the callback with the loaded object
      }

      // After successfully loading, fetch and store in cache
      const response = await fetch(url);
      if (response && response.ok) {
        const cache = await caches.open(characterCacheName);
        await cache.put(url, response.clone());
        console.log(`Character ${name} cached in Cache Storage.`);
      }
    });
  }

  worker.postMessage(url);
}

// Function to load the character from a blob URL
function loadFromBlob(blobUrl, name, callback) {
  const loader = new FBXLoader();

  loader.load(blobUrl, function (object1) {
    object1.scale.set((600 / 100000) + 0.01, 0.014, 0.007);
    object1.position.x = -0.07;
    object1.position.y = -0.8;

    window.cha = object1;

    const mixer = new THREE.AnimationMixer(object1);
    window.mix[name] = mixer;
    window.mixer = mixer;
    object1.name = name;
    window.ActiveChar = name;

    scene.add(object1);

    object1.traverse(function (node) {
      if (node.name == "Main") {
        node.visible = false;
      }

      if (node.name.includes("clothes")) {
        if (
          node.name !== "fares_clothes_tie1_blueblack" &&
          node.name !== "fares_clothes_orignal_pants_blueblack" &&
          node.name !== "fares_clothes_orignal_shirt_aquamarine" &&
          node.name !== "fares_clothes_orignal_shoes_Havana"
        ) {
          node.visible = false;
        }
      }
    });

    hideLoaders();

    window.loaded[name] = true;
    window.loaChar = 1;
    console.log("Character loaded.");

    firstanimation(window.mixer,"change");

    if (callback) {
      callback(object1); // Invoke the callback with the loaded object
    }
  });
}



              

      /*  function crossfade(actionFrom, actionTo, duration) {
          // Start fading out the current action
          //actionFrom.fadeOut(duration);
          actionFrom.crossFadeTo(actionTo, duration, true);
          // Ensure the action to fade in starts from the beginning
          actionTo.reset();

          // Start fading in the new action
          actionTo.fadeIn(duration);

          // Start playing the new action
          actionTo.play();
          console.log(renderer.domElement);
        }*/

        function setActiveAction(name) {
          var action = actions[name];

          action.play();


          activeAction = action;
        }


        function loadedByName(name) {

          return window.activeChar== name;

        }
function hideLoaders(){
      if(window.characterLoadFinished)
      {
        window.characterLoadFinished();
      }
      if(window.flutter_inappwebview)
      {
        window.flutter_inappwebview.callHandler('characterLoadFinished');
      }


      $("#loader").delay(1000).fadeOut();
      $("#loader-hand").delay(2000).fadeOut();
     // $('body').delay(3000).addClass('player-actived');
  }

        function animate() {
          requestAnimationFrame(animate);

          delta = clock.getDelta();
          if (mixer) mixer.update(delta);

          renderer.render(scene, camera);
        }

        initiatePlayer();

        window.showClothesVisibility = showClothesVisibility;
        window.hideClothesVisibility = hideClothesVisibility;
        window.changeLanguage = changeLanguage
        window.stopAllAnimations = stopAllAnimations
        window.loadedByName = loadedByName;
        window.setwidthAndheigh = setwidthAndheigh;
        window.changeSpeed = changeSpeed;
        window.changeBackgroundColor = changeBackgroundColor;
        window.mountAll = mountAll;
        window.getExpression = getExpression;
        window.firstanimationStop = firstanimationStop;
        window.changeBackgroundImage = changeBackgroundImage;
        window.getAdAnimate = getAdAnimate;
        window.fnAnimateText = fnAnimateText;
        window.adjustCameraBasedOnCharacter = adjustCameraBasedOnCharacter;
        window.changeCharacter = changeCharacter;
        window.replay = replay;
        window.default_outfit = default_outfit;
        window.without_outfit = without_outfit;
        window.getClothesNames = getClothesNames;

        
      },


      onUpdated() {
      },
      alert(e) {
        alert(1)
      }
    }
  </script>

</character>